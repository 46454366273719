
export default function InstrumentTypeCard({ instrumentType, image, description }) {

    return (
        <div class="instrument-type-card">
          <img class="instrument-type-img" src={`images/${image}`}></img>
          <div id="instrument-type-content">
            <h2 class="instrument-type-header">{instrumentType}</h2>
            <p>
              {description}
            </p>
          </div>
        </div>
    );
    }