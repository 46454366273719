import React from "react";
// We use Route in order to define the different routes of our application
import { Route, Routes } from "react-router-dom";
 // We import all the components we need in our app
import Navbar from "./components/navbar";
import RecordList from "./components/recordList";
import Layout from "./components/layout";
import Home from "./components/home";
import Instrument from "./components/instrument";
import UpcomingPage from "./components/upcomingPage";
import LandingPage from "./components/landingPage";


 const App = () => {
  // set a router down below for the index page that displays RecordList
 return (
     <Routes>
       <Route path="/" element={<Layout />} >
         <Route index element={<LandingPage />} />
         <Route path="instruments" element={<Home />} />
         <Route path="home" element={<Home />} />
         <Route path="instrument/:instrumentName" element={<Instrument />} />
         <Route path="upcoming" element={<UpcomingPage />} />
        </Route>
     </Routes>
 );
};
export default App;