import React from 'react';
import '../assets/css/SearchBar.css';

export default function SearchBar({ searchInput, setSearchInput, selectedTag, setSelectedTag, tags }) {

    const handleSearch = (event) => {
        event.preventDefault(); // Prevent default form submission behavior
    };

    return (
        <div className="search-bar-container">
            <form onSubmit={handleSearch} style={{ width: '100%', display: 'flex' }}>
                <input
                    className="search-input"
                    type="text"
                    placeholder="Search Instrument by Name"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                />
                <select className="search-button"
                    onChange={(e) => setSelectedTag(e.target.value)}
                    value={selectedTag}
                    style={{ color: "white", fontWeight: "bold", padding: "10px", marginLeft: "10px", borderRadius: "5px" }}
                >
                    <option style={{color: "black"}} value="">ALL INSTRUMENTS</option>
                    {tags.map(tag => (
                        <option style={{color: "black"}} key={tag} value={tag.toLowerCase()}>{tag.toUpperCase()}</option>
                    ))}
                </select>
            </form>
        </div>
    );
}