import React from "react";
import data from "./updated_instruments.json";
import { useNavigate } from "react-router-dom";
import { Card, CardContent, Typography, CardActions, Button } from "@mui/material";
import '../assets/css/instrumentCard.css';

export default function InstrumentCard({ name }) {

    const navigate = useNavigate();

    const navigateToDetails = () => {
        navigate(`/instrument/${name}`);
    };

    const findImageURL = (name) => {
        // Convert the search name to lowercase (or uppercase) for case-insensitive comparison
        const lowerCaseName = name.toLowerCase();
    
        // Find the instrument with a case-insensitive match
        const instrument = data.find((instrument) => instrument.name.toLowerCase() === lowerCaseName);
    
        if (instrument) {
            return `images/${instrument.attachments.split(", ")[0]}`;
        } else {
            console.error("Instrument not found");
        }
    };
    

    console.log(name);

    return (
        <Card variant="outlined" className="card" style={{ width: "100%" }}>
            <CardContent className="instrument-card" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Typography variant="h5" component="div" fontSize="27pt" style={{ color: '#FFFFFF' }}>
                    {name}
                </Typography>
                <div style={{ width: "100%", display: "flex", justifyContent: "center"  }}>
                    <img
                        src={findImageURL(name)}
                        alt={name}
                        style={{ maxWidth: "100%", height: "400px" }}
                    />
                </div>
            </CardContent>
            <CardActions className="details-card"> 
                <Button className="details-button" onClick={navigateToDetails} style={{ fontSize: "16px", color: "#ff2400" }}>MORE!</Button>
            </CardActions>
        </Card>
    );
}