import React, { useState } from "react";
import data from "./updated_instruments.json";
import { useParams, useNavigate } from "react-router-dom";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import "../assets/css/Instrument.css";
import ErrorIcon from '@mui/icons-material/Error';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import CasinoIcon from '@mui/icons-material/Casino';
import HardwareIcon from '@mui/icons-material/Hardware';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import MessageIcon from '@mui/icons-material/Message';
import KeyIcon from '@mui/icons-material/Key';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

export default function Instrument() {
  const { instrumentName } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  
  const goBackToHome = () => {
    navigate("/");
  };
  
  const instrument = data.find(
    (instrument) => instrument.name === instrumentName
  );

  if (instrument == null) {
    return (
      <div>
        <h2>Instrument not found</h2>
        <button onClick={goBackToHome}>Go back to the home page</button>
      </div>
    );
  }

  const findImageURL = (name) => {
    const instrument = data.find((instrument) => instrument.name === name);
    if (instrument) {
      return `../images/${instrument.attachments.split(", ")[0]}`;
    } else {
        console.error("instrument not found");
    }
  };

  const imageUrls = instrument.attachments.split(", ");

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
    <div className="top-Background">
      <img
          src={findImageURL(instrument.name)} 
          alt={instrument.name}
          className="instrument-photo"
        />
        </div>
      <div className="instrument-Background">
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant="fullWidth"
        TabIndicatorProps={{
          style: {
            backgroundColor: "white",
          },
        }}
      >
        <Tab
          label="Biography"
          sx={{
            fontSize: "2.5vw",
            color: "white",
            "&.Mui-selected": { color: "red" },
          }}
        />
        <Tab
          label="Audio"
          sx={{
            fontSize: "2.5vw",
            color: "white",
            "&.Mui-selected": { color: "red" },
          }}
        />
        <Tab
          label="Gallery"
          sx={{
            fontSize: "2.5vw",
            color: "white",
            "&.Mui-selected": { color: "red" },
          }}
        />
      </Tabs>
      <TabPanel value={activeTab} index={0}>
        <Typography component="div">
        <Box p={3}>
        <p className="instrument-title"> {instrument.name} </p>
          <p className="instrument-name">
              <span className="instrument-name">
                {capitalizeFirstLetter(instrument.instrument_group)}
              </span>
            </p>
          <div className="attribute-container">
          <div className="instrument-attribute personality-overview">
          <div className="icon-container">
            <MessageIcon style={{ fontSize: "2.5rem" }} />
          </div>
            <p className="attribute-name">Personality</p>
            <hr className="attribute-line" />
            <p className="attribute-value">{instrument.personality_overview}</p>
          </div>
          
          <div className="instrument-attribute secret">
          <div className="icon-container">
          <KeyIcon style={{ fontSize: "2.5rem" }} />
        </div>
            <p className="attribute-name">Secret</p>
            <hr className="attribute-line" />
            <p className="attribute-value">{instrument.secret}</p>
          </div>

          <div className="instrument-attribute worst-trait">
          <div className="icon-container">
            <RemoveCircleIcon style={{ fontSize: "2.5rem" }} />
          </div>
            <p className="attribute-name">Worst Trait</p>
            <hr className="attribute-line" />
            <p className="attribute-value">{instrument.worst_trait}</p>
          </div>

        <div className="instrument-attribute important">
        <div className="icon-container">
          <ErrorIcon style={{ fontSize: "2.5rem" }} />
        </div>
          <p className="attribute-name">Important</p>
          <hr className="attribute-line" />
          <p className="attribute-value">{instrument.important}</p>
        </div>

        <div className="instrument-attribute dislikes-most">
        <div className="icon-container">
          <ThumbDownIcon style={{ fontSize: "2.5rem" }} />
        </div>
          <p className="attribute-name">Dislikes Most</p>
          <hr className="attribute-line" />
          <p className="attribute-value">{instrument.dislikes_most}</p>
        </div>

        <div className="instrument-attribute plays-with-others">
        <div className="icon-container">
          <CasinoIcon style={{ fontSize: "2.5rem" }} />
        </div>
          <p className="attribute-name" style={{ fontSize: "12pt" }}>Plays with Others</p>
          <hr className="attribute-line" />
          <p className="attribute-value">{instrument.plays_with_others}</p>
        </div>

        <div className="instrument-attribute constituent-parts">
        <div className="icon-container">
          <HardwareIcon style={{ fontSize: "2.5rem" }} />
        </div>
          <p className="attribute-name">Parts</p>
          <hr className="attribute-line" />
          <p className="attribute-value">{instrument.constituent_parts}</p>
        </div>
        </div>
        </Box>
        </Typography>
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <Typography component="div">
          <Box p={3}>
          <p className="instrument-title"> {instrument.name} </p>
          <p className="instrument-name">
              <span className="instrument-name">
                {capitalizeFirstLetter(instrument.instrument_group)}
              </span>
            </p>
            {/* Check if instrument.audio_samples exists and is not an empty string */}
            {instrument.audio_samples &&
              instrument.audio_samples.trim() !== "" &&
              instrument.audio_samples.split(",").map((sample, index) => {
                // Trim the filename to remove any leading/trailing whitespaces
                const name = sample.trim().split(" (")[0]; // Get the name before the URL and trim it
                // Use the trimmed name in the src attribute
                const audioSrc = `/audio_samples/${name.trim()}`; // Ensure any spaces are removed
                return (
                  <div key={index}>
                    <p className="filename-centre">{name}:</p> {/* Displaying the trimmed name */}
                    <audio className="audio-player" controls src={audioSrc}>
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                );
              })}
          </Box>
        </Typography>
      </TabPanel>

      <TabPanel value={activeTab} index={2}>
        <Typography component="div">
        <Box p={3}>
          <p className="instrument-title"> {instrument.name} </p>
          <p className="instrument-name">
              <span className="instrument-name">
                {capitalizeFirstLetter(instrument.instrument_group)}
              </span>
            </p>
        </Box>
        <div>
          <Carousel showThumbs={false}>
            {imageUrls.map((imageUrl, index) => (
              <div key={index} style={{ marginLeft:"auto",marginRight:"auto", width:"30%", height:"auto"}}>
              <img src={`../images/${imageUrl}`} alt={`Instrument ${index + 1}`} />
            </div>
            ))}
          </Carousel>
        </div>
        </Typography>
      </TabPanel>
    </div>
    </>
  );
}

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
