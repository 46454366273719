import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { NavLink } from "react-router-dom";
import '../assets/css/Navbar.css';
 // Here, we display our Navbar
export default function Navbar() {
 return (
   <div className="navbar">
     <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="navbar-brand">
      <NavLink to="/">
      <img style={{"width" : 100 + '%'}} src={require(`../logos/instrumentbios.png`)} alt="instrumentbios logo"></img>
      </NavLink>
       <button
         className="navbar-toggler"
         type="button"
         data-bs-toggle="collapse"
         data-bs-target="#navbarSupportedContent"
         aria-controls="navbarSupportedContent"
         aria-expanded="false"
         aria-label="Toggle navigation"
       >
         <span className="navbar-toggler-icon"></span>
       </button>
        </div>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
         <ul className="navbar-nav ms-auto">
           <li className="nav-item">
             <NavLink className={({ isActive }) => isActive ? 'nav-link active-nav-link' : 'nav-link'} 
             to="/instruments">
               Instruments
             </NavLink>
           </li>
           <li className="nav-item">
             <NavLink className={({ isActive }) => isActive ? 'nav-link active-nav-link' : 'nav-link'} 
             to="/upcoming">
               Events
             </NavLink>
           </li>
           <li className="nav-item">
           <a href="https://scrapartsmusic.com/about" target="_blank" rel="noopener noreferrer" className="nav-link">
               About
               </a>
           </li>
           <li className="nav-item">
           <a href="https://scrapartsmusic.com/shop" target="_blank" rel="noopener noreferrer" className="nav-link">
               Merchandise
               </a>
           </li>
         </ul>
       </div>
     </nav>
   </div>
 );
}