import React from "react";
import "../assets/css/LandingPage.css";
import InstrumentTypeCard from "./InstrumentTypeCard";

export default function LandingPage() {
  return (
    <div id="landing-main-container">
      <div id="welcome-container">
        <div id="welcome-background">
          <img className="welcome-img" src="images/Alumoplate1.jpg"></img>
          <img className="welcome-img" src="images/Bagoleon2.jpg"></img>
          <img className="welcome-img" src="images/Whorlies1.jpg"></img>
          <img className="welcome-img format-image" src="images/Pippolini1.jpg"></img>
          <img className="welcome-img format-image" src="images/LittleGongArray1.jpg"></img>
          <img className="welcome-img format-image" src="images/Bagoleon3.jpg"></img>
        </div>
        <div id="welcome-text-container">
          <h1 class="landing-header">Welcome to our Instrument Bios App!</h1>
          <p>
          Scrap Arts Music is a Canadian percussion group that performs with recycled materials. Since 1998 we have played in over 17 countries, and we and our instruments were featured in the Closing Ceremonies of the 2010 Winter Olympics beamed to millions! We launched our latest production Children of Metropolis in 2024 and can’t wait to share it with you.  
          </p>
          <p>
          View and listen to our instruments, and learn about the different types of instruments we create. We hope you enjoy this deep dive into the instrument bios!
          </p>
        </div>
      </div>

      <div class="landing-container">
        <div id="about-container">
          <div id="about-text-container">
            <h1 class="landing-header">About Scrap Arts Music</h1>
            <p>
            Scrap Arts Music is a Canadian percussion group founded in 1998 by Gregory Kozak and Justine Murdy. The company is known for its use of recycled materials in performances. The group has performed in over 17 countries, including Canada, the United States, China, Australia and Countries in Europe. Scrap Arts Music has received critical acclaim for its innovative performances.
            </p>
          </div>
        </div>
        <img id="about-img" src="images/scrapartsmusic-2.jpg"></img>
      </div>

      <div class="landing-container">
        <img id="instruments-img" src="images/ZigguratDrum4.jpg"></img>
        <div id="instrument-container">
          <h1 class="landing-header">Our Instruments</h1>
          <p>
            Scrap Arts Music uses many unique instruments in their performances, all designed and fabricated by Gregory Kozak. These instruments are made from recycled materials, such as aluminum, steel, and plastic, and are designed to produce a wide range of sounds and are used to create complex rhythms and melodies. Scrap Arts Music's instruments are an integral part of their performances and are a key element of their distinctive sound.
          </p>
          <a href="/instruments" class="landing-link">View Our Instruments</a>
        </div>
      </div>

      <div id="landing-instrument-types-container">
        <div id="instrument-types-description">
          <h1 id ="types-header">Instrument Types</h1>
          <div id="types-description">
            <p>
            Scrap Arts Music makes use of over 145 hand-built instruments in each performance. To organize them for discussion, we use the Sachs Hornbostel Classification System. First used in 1914, this system identifies an instrument according to what part of it first produces the initial sound. Enjoy the discovery!
            </p>
          </div>
        </div>
        <div id="instrument-type-cards-container">
          <InstrumentTypeCard instrumentType={"Idiophones"} image={"Alumoplate1.jpg"} description={"Idiophones are instruments that produce sound by vibrating the entire body of the instrument itself. Examples of idiophones include bells, cymbals, and gongs."} />
          <InstrumentTypeCard instrumentType={"Membranophones"} image={"ZigguratDrum1.jpg"} description={"Membranophones are instruments that produce sound by vibrating a stretched membrane. Examples of membranophones include drums and tambourines."} />
          <InstrumentTypeCard instrumentType={"Aerophones"} image={"Whorlies1.jpg"} description={"Aerophones are instruments that produce sound by vibrating a column of air. Examples of aerophones include flutes, trumpets, and bagpipes."} />   
          <InstrumentTypeCard instrumentType={"Chordophones"} image={"TheMojo3.jpg"} description={"Chordophones are instruments that produce sound by vibrating a stretched string. Examples of chordophones include guitars, violins, and harps."} />
        </div>
      </div>

    </div>
  );
}