import React, { useState, useEffect } from 'react';
import EventCard from './eventCard'; // Make sure to import EventCard

export default function UpcomingPage() {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://rest.bandsintown.com/artists/Scrap%20Arts%20Music/events/?app_id=f8f6f8e8133a15485b209498a5878b3a');
        const data = await response.json();
        setEvents(data); // Store the entire event objects
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <p>Loading...</p>;
  }

 return (
  <div>
      <h1>Upcoming Events</h1>
      {events.map((event, index) => (
        // Pass the entire event object to the EventCard
        <EventCard key={event.id} event={event} />
      ))}
  </div>
);
}
