import React, { useState } from 'react';
import data from "./updated_instruments.json";
import { Grid } from "@mui/material";
import '../assets/css/Home.css';
import SearchBar from "./searchBar";
import { Link } from "react-router-dom";
import Navbar from "./navbar";
import InstrumentCard from "./instrumentCard";

export default function Home() {
    const [searchInput, setSearchInput] = useState("");
    const [selectedTag, setSelectedTag] = useState("");
    const tags = ["idiophone", "aerophone", "membranophone", "chordophone"];

    const filteredData = data.filter(instrument =>
        instrument.name.toLowerCase().includes(searchInput.toLowerCase()) &&
        (selectedTag === "" || instrument.instrument_group === selectedTag)
    );

    return (
        <div className='homeBackground'>
            <SearchBar 
                searchInput={searchInput} 
                setSearchInput={setSearchInput} 
                selectedTag={selectedTag}
                setSelectedTag={setSelectedTag}
                tags={tags} 
            />
            <Grid container spacing={2}>
             {filteredData.map((instrument, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <InstrumentCard name={instrument.name} />
              </Grid>
          ))}
      </Grid>
        </div>
    );
}