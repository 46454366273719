import React, { useState } from 'react';
import '../assets/css/EventCard.css';

function EventCard({ event }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { title, datetime, venue } = event;

  const date = new Date(`${datetime}`);

  const options = { 
    weekday: 'long', 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric', 
    hour: 'numeric', 
    minute: 'numeric', 
    hour12: true 
  };

  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <div className="event-card" onClick={toggleModal}>
        <h2>{title}</h2>
        <p>Date and Time: {formattedDate}</p>
        <p>Location: {venue.name}, {venue.city}, {venue.country}</p>
      </div>

      {isModalOpen && (
        <Modal event={event} onClose={toggleModal} />
      )}
    </>
  );
}

function Modal({ event, onClose }) {
  const { title, datetime, description, venue, offers } = event;
  const artistId = "291791";

  // Code that formats the date and time
  const date = new Date(`${datetime}`);

  const options = { 
    weekday: 'long', 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric', 
    hour: 'numeric', 
    minute: 'numeric', 
    hour12: true 
  };

  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
  // end of code that formats the date and time
  
  const eventId = event.id;

  const handleBuyTicketClick = (url) => {
    window.open(url, '_blank');
  };

  const handleSetReminderClick = () => {
    if (!eventId) {
      console.error("Event ID is missing.");
      return;
    }
    const reminderUrl = `https://www.bandsintown.com/artist-rsvp/${artistId}?event_id=${eventId}&utm_campaign=event&utm_medium=api&app_id=Bandzoogle&utm_source=public_api&came_from=267&spn=1&signature=ZZ194380210bb83321aadd77c2fa5803540e9f5de231eee1b138faa53ad0a525af`;
    window.open(reminderUrl, '_blank');
  };

  const renderTicketInfo = () => {
    if (offers && offers.length > 0) {
      if (offers[0].status === 'available') {
        return <button onClick={() => handleBuyTicketClick(offers[0].url)}>Buy Tickets</button>;
      } else {
        return <p>Tickets Not Available</p>;
      }
    } else {
      return <p>Tickets Not Available</p>;
    }
  };

  return (
    <div className="modal-backdrop">
        <div className="modal-content">
            <button onClick={onClose} className="close-modal-button">&times;</button>
            <h2>{title}</h2>
            <p>{formattedDate}</p>
            <p>{description}</p>
            <p>Location: {venue.name}, {venue.city}, {venue.country}</p>
            <div className="ticket-info">
                {renderTicketInfo()}
                <button onClick={handleSetReminderClick}>Set Reminder</button>
            </div>
            {/* <button onClick={onClose}>Close</button> */}
        </div>
    </div>
);
}

export default EventCard;
